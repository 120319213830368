import { useEffect, useState } from "react";
import { Builder, BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import UserEmailForm from "../ui-components/UserEmailForm";
import { toast } from "react-toastify";
// Put your API key here
const builderIoApiKey = process.env.REACT_APP_BUILDER_IO_API_KEY;
builder.init(builderIoApiKey);
// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
const BuilderPage = () => {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState();
  // get the page content from Builder
   useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: window.location.pathname
        })
        .promise();
      setContent(content);
      setNotFound(!content);
      // if the page title is found,
      // set the document title
      if (content?.data.title) {
       document.title = content.data.title
      }
    }
    fetchContent();
  }, [window.location.pathname]);
  // If no page is found, return
  // a 404 page from your code.
  // The following hypothetical
  // <FourOhFour> is placeholder.
  if (notFound && !isPreviewingInBuilder) {
    return <>{"FourOhFour !"}</>
  }
  // return the page when found

  const userEmailForm = () => <><UserEmailForm onSuccess={() => {
    toast.success('Thanks for providing your email address !', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
      });
  }} /></>

  Builder.registerComponent(userEmailForm, { 
    name: 'EmailForm',
    image: 'https://tabler-icons.io/static/tabler-icons/icons-png/3d-cube-sphere-off.png'
  })

  return (
    <>
      {/* Render the Builder page */}
      <BuilderComponent model="page" content={content} />
    </>
  );
}
export default BuilderPage