import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';

import 'react-toastify/dist/ReactToastify.css';

import awsconfig from './aws-exports';

import "@aws-amplify/ui-react/styles.css";
import { studioTheme } from "./ui-components";
import { ToastContainer } from 'react-toastify';

import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";

const gAnalyticsMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
ReactGA.initialize(gAnalyticsMeasurementId);
Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={studioTheme}>
      <App />
    </ThemeProvider>
    <ToastContainer/>
  </React.StrictMode>
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

reportWebVitals(SendAnalytics);