import BuilderPage from "./components/Builder";
import UserEmailForm from "./ui-components/UserEmailForm";

function App() {
  return (
    <>
      <BuilderPage/>
    </>
  );
}

export default App;
